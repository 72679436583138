.modules-selector {
  width: 194px;
}

.modules-selector-description {
  max-width: 311px;
}

.attendants-table-container {
  & th {
    font-size: 16px !important;
  }

  & th:first-of-type {
    padding-left: 71px;
    min-width: 210px;
  }

  & th:nth-of-type(2) {
    text-align: center;
  }
}

.attendance-student-picture {
  background-color: #cecece;
  border-radius: 50%;
  height: 41px;
  width: 41px;

  & img {
    height: 100%;
  }
}

.end-btns-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  .btn {
    width: 280px;

    &:first-of-type {
      margin-right: 1rem;
    }
  }
}

.status-toggler {
  display: flex;
  align-items: center;

  & > .status-btn:not(:last-of-type) {
    margin-right: 0.5rem;
  }
}

.status-btn {
  width: 98.5px;

  &.Present.selected,
  &.Present:hover,
  &.Present:active {
    background-color: #cef0da !important;
    color: $primary !important;
  }

  &.Absent.selected,
  &.Absent:hover,
  &.Absent:active {
    background-color: #d101253b !important;
    color: $primary !important;
  }

  &.Justified.selected,
  &.Justified:hover,
  &.Justified:active {
    background-color: #ffe6a1 !important;
    color: $primary !important;
  }
}

@media (max-width: 720px) {
  .end-btns-container {
    .btn {
      width: 100%;

      &:first-of-type {
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }
  }
}

@media (min-width: 975px) and (max-width: 1090px) {
  .modules-selector-description {
    max-width: 200px;
  }
}
