.attendance-tracking-table-wrapper {
  /* Container that wraps both tables */
  .table-container {
    display: flex;
    overflow-x: auto;
    height: 70vh;
  }

  .table-container table thead {
    position: sticky;
    top: 0;
  }

  /* Students table styles*/
  table.students-table {
    table-layout: fixed;
    border-collapse: collapse;
    width: 104.8px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #fffcfc;
    padding: 8px 12px;
    height: fit-content;
    box-shadow: 0px 6px 6px #00000029;
    border-radius: 4px !important;

    position: sticky;
    left: 0;
    z-index: 3;
  }

  table.students-table thead {
    height: 134.5px;
  }

  table.students-table th {
    background: $secondary;
    position: sticky;
    top: 0px;
  }

  table.students-table td {
    border: 0;
    padding: 12.25px 0px;
    color: #5a5a5a;
    background: white;
  }

  table.students-table td p {
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  table.students-table th.name-header {
    width: 220px;
  }

  table.students-table th.attendance-header {
    width: 200px;
    vertical-align: bottom;
  }

  /* Attendance table styles */
  table.attendance-table {
    border-collapse: separate !important;
    border-spacing: 0;
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    color: white;
    height: fit-content;
    border-radius: 4px;
    width: 100%;
  }

  table.attendance-table thead {
    z-index: 2;
  }

  table.attendance-table th {
    background: $secondary;
    border: 1px solid $primary;
    padding: 8px 0px;
    font-weight: 600;
    transition: 0.1s linear background;
  }

  table.attendance-table th:first-of-type {
    border-left: 0 !important;
  }

  table.attendance-table td {
    border: 0;
    padding: 12.25px 0px;
    font-size: 14px;
    color: #5a5a5a;
    background: white;
    border: none;
    width: 36px;
  }

  table.attendance-table tr th:last-of-type {
    border: none;
  }

  table.attendance-table tr td:last-of-type {
    width: auto;
  }

  table.attendance-table td:first-of-type {
    padding-left: 8px;
  }

  table.attendance-table th.first-module:not(:first-of-type) {
    border-left: 1px solid $primary;
  }

  table.attendance-table th.month-header {
    text-transform: capitalize;
    padding-left: 0px;
    padding-right: 0px;
    border-top: 0;
  }

  table.attendance-table th.module-header {
    border: 0;
    border-top: 1px solid $primary;
    height: 36px;
    font-size: 12px;
  }

  table.attendance-table th.last-module {
    border-right: 1px solid $primary;
  }

  table.attendance-table th.module-header:last-of-type {
    border-right: 1px solid $primary;
  }

  table.attendance-table.scrolling-over th {
    background-color: lighten($secondary, 4%);
    border-color: $primary;
  }
}
