.radio-button-table {
  --size: 18px;
  width: var(--size);
  height: var(--size);
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  padding: 6px;
  /* background-color only for content */
  background-clip: content-box;
  border: 1px solid $primary;
  background-color: white;
  border-radius: 50%;
}

.radio-button-table:checked {
  background-color: $primary !important;
  border: 1px solid $primary !important;
  padding: 2px;
}
