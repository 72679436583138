.box-class-type-legend {
  align-items: center;
  display: flex;
  gap: 6px;
  position: relative;

  &::before {
    border-radius: 4px;
    content: "";
    height: 20px;
    width: 24px;
  }

  &.type-course {
    &::before {
      background-color: $schedule-color-1;
    }
  }
  &.type-assistantship {
    &::before {
      background-color: $schedule-color-2;
    }
  }
  &.type-lab {
    &::before {
      background-color: $schedule-color-3;
    }
  }
  &.type-field {
    &::before {
      background-color: $schedule-color-4;
    }
  }
  &.type-workshop {
    &::before {
      background-color: $schedule-color-5;
    }
  }
  &.type-practice {
    &::before {
      background-color: $schedule-color-6;
    }
  }
  &.type-thesis {
    &::before {
      background-color: $schedule-color-7;
    }
  }
  &.type-other {
    &::before {
      background-color: $schedule-color-8;
    }
  }
}
