@import '_attendance_tracking_table.scss';

.attendance-tracking-search-container {
  width: 350px;

  .form-group {
    margin-bottom: 0 !important;
  }
}

.attendance-tracking-period-filter-container {
  width: 194px;

  .form-group {
    margin-bottom: 0 !important;
  }
}

.tracking-status-box {
  border: 1px solid $primary;

  &.present-bg {
    background: #cef0da;
  }
  &.absent-bg {
    background: #d101253b;
  }

  &.justified-bg {
    background: #ffe6a1;
  }

  &.blocked {
    background: #f0f0f0;
  }
}

.attendance-tracking-status-selector {
  position: relative;
  user-select: none;

  &:hover,
  &.active {
    .icon-container {
      opacity: 1;
    }
  }

  .icon-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: 0.03s linear opacity, 0.07s ease-in transform;
  }

  &.active .icon-container {
    transform: translate(-50%, calc(-50% + 2px)) rotate(180deg);
  }

  .options-container {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    background-color: white;
    border-radius: 4;
    box-shadow: 0px 3px 6px #00000029;
    text-align: left;
    display: none;
    z-index: 1;
  }

  &.active .options-container {
    display: block;
  }

  .option {
    display: flex;
    align-items: center;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
}
