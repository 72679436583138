.empty-state-msg {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 3rem;
  width: 100%;

  .icon-container {
    background-color: $primary;
    border-radius: 50%;
    padding: 0.9rem 1rem;
    margin-bottom: 27px;
  }

  .message {
    color: $color-dark;
    font-size: 20px;
    text-align: center;
    width: 100%;
  }
}
