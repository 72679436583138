.todays-classes-section {
  background-color: $color-lightest;
  min-height: 284.4px;

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
  }
}

.class-card {
  width: 100%;
  max-width: 462px;
  overflow: hidden;

  &:last-of-type {
    margin-right: 0 !important;
  }

  .data-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    & > div {
      width: calc(33.33% - 8px);

      &:last-of-type {
        flex-grow: 1;
      }

      p:nth-of-type(2) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

   
  }

  .btn-container {
    align-items: center;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    width: 100%;

    .btn {
      min-height: 31px;
      width: 203px;

      .can-grow {
        max-width: 100% !important;
      }
    }

    .status-label {
      height: 31px;
      width: 129px;
      opacity: 0.5;
    }
  }
}

@media (max-width: 1170px) {
  .class-card {
    max-width: 380px;
    margin-bottom: 1rem;
    margin-right: 1.5rem !important;
  }

  .box-class-type-legend::before {
    display: none;
  }
}

@media (max-width: 992px) {
  .class-card {
    max-width: 48%;

    .btn-container {
      .btn {
        max-width: 143px;
        padding: 2px 7px !important;
        width: 100%;
      }
    }
  }
}

@media (max-width: 768px) {
  .class-card {
    max-width: 100%;
    margin-right: 0 !important;

    .btn-container {
      &:not(.inProgress) {
        justify-content: center;
      }

      .btn.can-grow {
        max-width: 203px;
      }

      &:first-child {
        display: none;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .class-card {
    .data-grid {
      & > div {
        width: calc(50% - 8px);
      }
    }
  }

  .todays-classes-section {
    padding: 0.5rem !important;

    & > div.d-flex {
      padding: 0rem !important;
    }
  }

 
}
