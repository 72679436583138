$schedule-color-1: #ffbd71;
$schedule-color-2: #adf7a8;
$schedule-color-3: #abcce9;
$schedule-color-4: #ee9ef8;
$schedule-color-5: #c0baf5;
$schedule-color-6: #96d192;
$schedule-color-7: #8eebe8;
$schedule-color-8: #ffa19d;

.g-week-schedule {
  margin-bottom: 0px;
  font-size: 15px;
  color: $color-light;
  text-align: center;
  font-weight: 400;
  thead {
    th {
      font-weight: 400;
      background-color: $color-lightest;
      border: 1px solid $color-lightest;
      padding: 24px 28px;
    }
  }
  tbody {
    td {
      border: 1px solid $body-bg;
      font-weight: 100;
      text-align: left;
      height: 44px;
    }
  }
}

.schedule-module {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 44px;
  padding: 5px 10px;
}

.classes-container {
  display: flex;
  // width: 150px;
  width: 100%;
  position: relative;
}

.schedule-class {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  border-left: 2px solid currentColor;
  position: absolute;
}

.g-legends-container {
  color: $color-dark;
  span.legend {
    width: 29px;
    height: 24px;
    float: left;
    margin-right: 12px;
    border-radius: 4px;
  }
}

// Colores de fondo para ser utilizados en los cuadros de legendas
// y fondo de cada horario
.bg-schedule-1 {
  background-color: $schedule-color-1;
}
.bg-schedule-2 {
  background-color: $schedule-color-2;
}
.bg-schedule-3 {
  background-color: $schedule-color-3;
}
.bg-schedule-4 {
  background-color: $schedule-color-4;
}
.bg-schedule-5 {
  background-color: $schedule-color-5;
}
.bg-schedule-6 {
  background-color: $schedule-color-6;
}
.bg-schedule-7 {
  background-color: $schedule-color-7;
}
.bg-schedule-8 {
  background-color: $schedule-color-8;
}

.bg-light-schedule-1 {
  background-color: rgba($schedule-color-1, 0.6);
  border-color: $schedule-color-1;
}
.bg-light-schedule-2 {
  background-color: rgba($schedule-color-2, 0.6);
  border-color: $schedule-color-2;
}
.bg-light-schedule-3 {
  background-color: rgba($schedule-color-3, 0.6);
  border-color: $schedule-color-3;
}
.bg-light-schedule-4 {
  background-color: rgba($schedule-color-4, 0.6);
  border-color: $schedule-color-4;
}
.bg-light-schedule-5 {
  background-color: rgba($schedule-color-5, 0.6);
  border-color: $schedule-color-5;
}
.bg-light-schedule-6 {
  background-color: rgba($schedule-color-6, 0.6);
  border-color: $schedule-color-6;
}
.bg-light-schedule-7 {
  background-color: rgba($schedule-color-7, 0.6);
  border-color: $schedule-color-7;
}
.bg-light-schedule-8 {
  background-color: rgba($schedule-color-8, 0.6);
  border-color: $schedule-color-8;
}
